import { useCallback, useEffect, useState } from 'react';
import { Batch } from '../../types/batches';
import api from '../../services/api';
import { addFormattedValuesToBatch } from './utils';

const useBatches = () => {
  const [data, setData] = useState<Batch[]>([]);
  const [loaded, setLoaded] = useState(false);

  const loadBatches = useCallback(async () => {
    setLoaded(false);
    const { data } = await api.get<Batch[]>('/batches');
    setData(data);
    setLoaded(true);
  }, []);

  useEffect(() => {
    loadBatches().catch(console.error);
  }, [loadBatches]);

  const formattedData = data.map((batch) => addFormattedValuesToBatch(batch));

  const getById = (batchId: string) => {
    return formattedData.find((batch) => batch.batch_id === batchId);
  };
  return { data: formattedData, refresh: loadBatches, getById, loaded } as const;
};

export default useBatches;
