import React, { useState } from 'react';
import { Col, Modal, Row, Stack } from 'react-bootstrap';
import { BuyBatchInfo } from '../../types/batches';
import BatchCreateForm from './BatchCreateForm';
import useWaitingForBatchTask from './useWaitingForBatchTask';
import * as batchService from '../../services/batch.service';
import useNetworkCheck from '../common/useNetworkCheck';
import { useAccount } from 'wagmi';
import TimeCalculator from './TimeCalculator';

type Props = {
  onAfterBuy: () => Promise<void>;
};

const BuyBatch = ({ onAfterBuy }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [taskId, setTaskId] = useState<string | null>(null);
  const [amount, setAmount] = useState<string>('0');

  // const [dir, setDir] = useState<'up' | 'down'>('down');
  const isCorrectNetwork = useNetworkCheck();
  const { address } = useAccount();

  const { waiting } = useWaitingForBatchTask({
    taskId,
    onSuccess: async () => {
      setTaskId(null);
      onAfterBuy && (await onAfterBuy());
      setShowModal(false);
    },
    onFail: () => setTaskId(null),
  });

  const buyClickHandler = async (amount: string) => {
    setAmount(amount);
    setShowModal(true);
  };

  const handleBuy = async (params: BuyBatchInfo) => {
    try {
      await batchService.approve(params.amount, address);
      const { task_id } = await batchService.createBatch(params);
      setTaskId(task_id);
      alert('Batch is being created. You will be notified when it is ready.');
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        alert(e.message);
      } else {
        alert(e as string);
      }
    }
  };

  return (
    <Stack className="align-items-center">
      {/*<h1 className="display-5 my-5 text-center">AFFORDABLE PRICING PLANS</h1>*/}
      <Row className="w-100 g-3">
        <Col lg={3}></Col>
        <Col lg={6}>
          <TimeCalculator
            buttonText={'Buy Batch'}
            onButtonClick={buyClickHandler}
            isProcessing={!!taskId}
            disabled={!!taskId || !isCorrectNetwork}
          />
        </Col>
        <Col lg={3}></Col>
      </Row>
      <Modal show={showModal} onHide={() => setShowModal(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Buy Batch</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BatchCreateForm waitingForBuy={waiting} onSubmit={handleBuy} price={amount} />
        </Modal.Body>
      </Modal>
    </Stack>
  );
};

export default BuyBatch;
