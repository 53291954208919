import { Badge, Button, Card, Col, Modal, Row, Stack } from 'react-bootstrap';
import { Bucket, View } from '../../types/buckets';
import FancyIcon from '../common/FancyIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHardDrive as bucketIcon, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { BatchWithFormattedValues } from '../../types/batches';
import useBatches from '../batches/useBatches';
import BucketMenuButton from './BucketMenuButton';
import { useState } from 'react';
import api from '../../services/api';
import { isAxiosError } from 'axios';

type BucketCardProps = {
  bucket: Bucket;
  view: View;
  batch?: BatchWithFormattedValues;
  onDelete?: () => void;
};
const BucketCard = ({ bucket, view, batch, onDelete }: BucketCardProps) => {
  const md = view === 'grid' ? 6 : 12;
  const lg = view === 'grid' ? 3 : 12;
  const dir = view === 'grid' ? 'vertical' : 'horizontal';
  return (
    <Col md={md} lg={lg} className="mb-4">
      <Card>
        <Card.Body>
          <Stack direction="horizontal" gap={3} className=" align-items-start">
            <Stack direction={dir} gap={3} className="w-100">
              <FancyIcon className="bg-primary-300 text-gray-800">
                <FontAwesomeIcon icon={bucketIcon} size="2x" />
              </FancyIcon>
              <Link
                to={`/${bucket._id}`}
                className={`stretched-link text-decoration-none fw-bold ${
                  view === 'list' ? 'w-50' : ''
                }`}
              >
                <Card.Title className="m-0 ellipsis">{bucket.Name}</Card.Title>
              </Link>
              <Card.Text className="small m-0">
                Batch:
                <Badge className="ms-2" bg="success">
                  {batch ? batch.formatted.label : 'No Batch'}
                </Badge>
              </Card.Text>
              <Card.Text className="small">
                {new Date(bucket.CreationDate).toLocaleDateString()}
              </Card.Text>
            </Stack>
            <BucketMenuButton onDelete={onDelete} />
          </Stack>
        </Card.Body>
      </Card>
    </Col>
  );
};

type Props = {
  buckets: Bucket[];
  view: View;
  onChange?: () => Promise<void> | void;
};
const BucketList = ({ buckets, view, onChange }: Props) => {
  const [bucketForDelete, setBucketForDelete] = useState<Bucket | null>(null);
  const [deleting, setDeleting] = useState(false);
  const { getById } = useBatches();
  const deleteHandler = async () => {
    if (!bucketForDelete) return;
    setDeleting(true);
    try {
      await api.delete(`/buckets/${bucketForDelete._id}`);
      setBucketForDelete(null);
      onChange && (await onChange());
    } catch (e) {
      console.error(e);
      if (isAxiosError(e)) {
        alert(e.response?.data?.message || e.message);
      } else {
        alert(e instanceof Error ? e.message : e);
      }
    } finally {
      setDeleting(false);
    }
  };
  return (
    <>
      <Row>
        {buckets.map((bucket) => (
          <BucketCard
            key={bucket._id}
            bucket={bucket}
            view={view}
            batch={getById(bucket.Owner)}
            onDelete={() => setBucketForDelete(bucket)}
          />
        ))}
      </Row>
      <Modal show={!!bucketForDelete} onHide={() => setBucketForDelete(null)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete this bucket <strong>{bucketForDelete?.Name}</strong>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setBucketForDelete(null)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={deleteHandler} disabled={deleting}>
            {deleting ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Delete'}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default BucketList;
