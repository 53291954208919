import { useAccount, useSignMessage } from 'wagmi';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Web3Button } from '@web3modal/react';
import { Image, Stack } from 'react-bootstrap';
import * as authService from '../services/auth.service';

const Login = () => {
  const { isConnected, connector } = useAccount();
  const navigate = useNavigate();
  const { signMessageAsync } = useSignMessage({
    message: 'Sign in to Protobox',
  });

  useEffect(() => {
    if (isConnected && connector) {
      signMessageAsync().then((signature) => {
        authService.saveSignature(signature);
        navigate('/');
      });
    }
  }, [isConnected, connector, signMessageAsync, navigate]);

  return (
    <Stack className="mt-5 p-4 align-items-center" gap={4}>
      <Image src="/images/logo.svg" />
      <h1 className="display-1">Welcome</h1>
      <h4 className="mb-5">Sign in to start your session</h4>
      <Web3Button />
    </Stack>
  );
};

export default Login;
