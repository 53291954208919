import { Button, Container, Nav, Navbar, Stack } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser as userIcon } from '@fortawesome/free-solid-svg-icons';
import { useAccount } from 'wagmi';
import { useWeb3Modal, Web3NetworkSwitch } from '@web3modal/react';
import { shortenAddress } from '../utils/eth.utils';
import { Link, useLocation } from 'react-router-dom';
import useBZZBalance from './batches/useBZZBalance';
import { ethers } from 'ethers';
import { ReactComponent as BzzIcon } from '../assets/swarm.svg';

const Header = () => {
  const location = useLocation();
  const { address } = useAccount();
  const web3modal = useWeb3Modal();
  const { data: bzzBalance } = useBZZBalance();
  const bzzBalanceFormatted = Number(
    typeof bzzBalance === 'bigint' ? ethers.formatUnits(bzzBalance, 16) : '0',
  ).toFixed(3);

  const navItems = [
    {
      name: 'Buckets',
      path: '/',
    },
    {
      name: 'Batches',
      path: '/batches',
    },
  ];

  return (
    <Navbar
      expand="md"
      sticky="top"
      className="bg-body-tertiary bg-opacity-75"
      style={{
        backdropFilter: 'blur(10px)',
      }}
    >
      <Container>
        <Navbar.Brand to="/" as={Link}>
          <img src="/images/logo.svg" alt="logo" height="30" />
        </Navbar.Brand>
        {/*<Badge bg="warning" className="text-dark me-auto">*/}
        {/*  {chain?.name}*/}
        {/*</Badge>*/}
        <Web3NetworkSwitch />
        <Navbar.Toggle aria-controls="basic-navbar-nav" />

        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            {navItems.map((item, index) => (
              <Nav.Link
                key={index}
                to={item.path}
                as={Link}
                active={location.pathname === item.path}
              >
                {item.name}
              </Nav.Link>
            ))}
          </Nav>
          <Stack direction="horizontal" gap={2}>
            <Button variant="secondary" onClick={() => web3modal.open()}>
              <FontAwesomeIcon icon={userIcon} />
              <span className="ms-3 d-none d-sm-inline">{shortenAddress(address)}</span>
            </Button>
            <Button variant="primary" onClick={() => web3modal.open()}>
              <BzzIcon width={24} height={24} />
              <span className="ms-2">{bzzBalanceFormatted} BZZ</span>
            </Button>
          </Stack>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default Header;
