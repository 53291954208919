import { Button, Dropdown } from 'react-bootstrap';
import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEllipsisV as menuIcon } from '@fortawesome/free-solid-svg-icons';

const CustomToggle = React.forwardRef(({ children, onClick }: any, ref: any) => (
  <Button
    ref={ref}
    variant="outline-secondary"
    size="sm"
    style={{ zIndex: 1, width: '1.7rem', height: '1.7rem' }}
    className="rounded-circle border-0 position-relative"
    onClick={(e) => {
      e.preventDefault();
      onClick(e);
    }}
  >
    {children}
  </Button>
));

type Props = {
  onDelete?: () => Promise<void> | void;
};
const FileMenuButton = ({ onDelete }: Props) => {
  return (
    <Dropdown>
      <Dropdown.Toggle as={CustomToggle}>
        <FontAwesomeIcon icon={menuIcon} />
      </Dropdown.Toggle>
      <Dropdown.Menu>
        <Dropdown.Item onClick={onDelete}>Delete</Dropdown.Item>
      </Dropdown.Menu>
    </Dropdown>
  );
};

export default FileMenuButton;
