import { useCallback, useEffect, useState } from 'react';
import api from '../../services/api';
import { File } from '../../types/files';

const useFiles = (bucketId: string, prefix: string) => {
  const [data, setData] = useState<File[]>([]);

  const loadFiles = useCallback(async () => {
    const { data } = await api.get<File[]>(`/buckets/${bucketId}/objects`, {
      params: { prefix },
    });
    setData(data);
  }, [bucketId, prefix]);

  useEffect(() => {
    loadFiles().catch(console.error);
  }, [loadFiles]);

  return { data, loadFiles };
};

export default useFiles;
