import { useCallback, useEffect, useState } from 'react';
import { Bucket } from '../../types/buckets';
import api from '../../services/api';

const useBuckets = () => {
  const [data, setData] = useState<Bucket[]>([]);

  const loadBuckets = useCallback(async () => {
    const { data } = await api.get<Bucket[]>('/buckets');
    setData(data);
  }, []);

  useEffect(() => {
    loadBuckets().catch(console.error);
    // setData([
    //   {
    //     _id: '5f9b1a1b9d9d8b0001b6d3a5',
    //     Name: 'bucket1',
    //     Owner: 'owner1',
    //     CreationDate: '2020-10-29T15:00:59.000Z',
    //   },
    //   {
    //     _id: '5f9b1a1b9d9d8b0001b6d3a6',
    //     Name: 'bucket2',
    //     Owner: 'owner2',
    //     CreationDate: '2020-10-29T15:00:59.000Z',
    //   },
    //   {
    //     _id: '5f9b1a1b9d9d8b0001b6d3a7',
    //     Name: 'bucket3',
    //     Owner: 'owner3',
    //     CreationDate: '2020-10-29T15:00:59.000Z',
    //   },
    //   {
    //     _id: '5f9b1a1b9d9d8b0001b6d3a8',
    //     Name: 'bucket4',
    //     Owner: 'owner4',
    //     CreationDate: '2020-10-29T15:00:59.000Z',
    //   },
    //   {
    //     _id: '5f9b1a1b9d9d8b0001b6d3a9',
    //     Name: 'bucket5',
    //     Owner: 'owner5',
    //     CreationDate: '2020-10-29T15:00:59.000Z',
    //   },
    //   {
    //     _id: '5f9b1a1b9d9d8b0001b6d3aa',
    //     Name: 'bucket6',
    //     Owner: 'owner6',
    //     CreationDate: '2020-10-29T15:00:59.000Z',
    //   },
    // ]);
  }, [loadBuckets]);

  const filterByBatchId = (batchId: string) => {
    return data?.filter((bucket) => bucket.Owner === batchId) || [];
  };

  const countByBatchId = (batchId: string) => {
    return filterByBatchId(batchId).length;
  };

  return { data, refresh: loadBuckets, countByBatchId, filterByBatchId };
};

export default useBuckets;
