import React, { useState } from 'react';
import { Button, ButtonToolbar, Form } from 'react-bootstrap';
import { normalizePath } from '../../utils/file.utils';
import api from '../../services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faSpinner,
  faUpload as uploadIcon,
  faCheckCircle as fileUploadedIcon,
} from '@fortawesome/free-solid-svg-icons';
import { useDropzone } from 'react-dropzone';

type Props = {
  bucketId: string;
  prefix: string;
  onSubmit?: () => Promise<void>;
};
const FileUploadForm = ({ bucketId, prefix, onSubmit }: Props) => {
  const [loading, setLoading] = useState(false);
  const [path, setPath] = useState('');
  const { getRootProps, getInputProps, isDragActive, acceptedFiles } = useDropzone();

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const key = buildKey();
    const file = acceptedFiles?.[0];
    if (!file) {
      return;
    }
    setLoading(true);
    try {
      const buffer = await file.arrayBuffer();
      await api.post(`/buckets/${bucketId}/objects/${key}`, buffer, {
        timeout: 30000,
        headers: {
          'Content-Type': file.type,
        },
      });
      onSubmit && (await onSubmit());
    } catch (e) {
      if (e instanceof Error) {
        alert(e.message);
      } else {
        alert(e as string);
      }
      throw e;
    } finally {
      setLoading(false);
    }
  };

  const buildKey = () => {
    let filename = '';
    if (acceptedFiles?.length) {
      const f = acceptedFiles[0];
      filename = f.name;
    }
    return normalizePath(`${prefix}/${path}/${filename}`);
  };

  return (
    <Form onSubmit={handleSubmit}>
      <Form.Group className="mb-3">
        <Form.Label>File</Form.Label>
        <div
          className={`p-4 text-center border-5 rounded-4 border-dark-subtle ${
            isDragActive ? 'bg-body-tertiary' : ''
          }`}
          style={{
            borderStyle: 'dashed',
            cursor: 'pointer',
          }}
          {...getRootProps()}
        >
          <input {...getInputProps({ multiple: false })} />
          {acceptedFiles.length ? (
            <FontAwesomeIcon icon={fileUploadedIcon} size="2x" className="mb-3 text-success" />
          ) : (
            <FontAwesomeIcon icon={uploadIcon} size="2x" className="mb-3" />
          )}
          <p>Drop files here or click</p>
        </div>
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>File Path (optional)</Form.Label>
        <Form.Control
          type="text"
          name="path"
          placeholder="Enter a file path. e.g: folder1/folder2"
          value={path}
          onChange={(e) => setPath(e.target.value)}
        />
      </Form.Group>
      <div className="w-100" style={{ lineBreak: 'anywhere' }}>
        <small>Key: {buildKey()}</small>
      </div>
      <ButtonToolbar className="d-flex justify-content-end">
        <Button type="submit" disabled={!acceptedFiles.length || loading}>
          {loading ? (
            <>
              <FontAwesomeIcon spin icon={faSpinner} className="me-2" />
              Uploading...
            </>
          ) : (
            'Upload'
          )}
        </Button>
      </ButtonToolbar>
    </Form>
  );
};

export default FileUploadForm;
