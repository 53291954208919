import { Form } from 'react-bootstrap';
import React, { useState } from 'react';
import { BatchWithFormattedValues } from '../../types/batches';
import useBZZBalance from './useBZZBalance';
import { ethers } from 'ethers';
import useWaitingForBatchTask from './useWaitingForBatchTask';
import { approve, extendBatch } from '../../services/batch.service';
import { useAccount } from 'wagmi';
import TimeCalculator from './TimeCalculator';

type Props = {
  batch: BatchWithFormattedValues;
  onExtend?: () => Promise<void>;
};

const BatchExtendForm = ({ batch, onExtend }: Props) => {
  const [loading, setLoading] = useState(false);
  const [taskId, setTaskId] = useState<string | null>(null);
  const { address } = useAccount();

  const { data: bzzBalance } = useBZZBalance();
  const bzzBalanceFormatted =
    typeof bzzBalance === 'bigint' ? ethers.formatUnits(bzzBalance, 16) : '0';

  const { waiting } = useWaitingForBatchTask({
    taskId,
    onSuccess: async () => {
      setTaskId(null);
      onExtend && (await onExtend());
    },
    onFail: () => setTaskId(null),
  });

  const handleExtend = async (price: string) => {
    // e.preventDefault();
    setLoading(true);
    try {
      const amount = ethers.parseUnits(price, 16);
      await approve(amount, address);

      const { task_id } = await extendBatch(batch.batch_id, Number(amount));
      setTaskId(task_id);
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        alert(e.message);
      } else {
        alert(e as string);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form>
      <h1>
        <small>Balance: </small>
        <strong>{bzzBalanceFormatted} BZZ</strong>
      </h1>
      <TimeCalculator
        disabled={loading || waiting}
        isProcessing={loading || waiting}
        buttonText="Extend"
        onButtonClick={handleExtend}
      />
    </Form>
  );
};

export default BatchExtendForm;
