import React, { useState } from 'react';
import { Alert, Button, Form, InputGroup, Modal } from 'react-bootstrap';
import { BuyBatchInfo } from '../../types/batches';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSpinner, faWarning as warningIcon } from '@fortawesome/free-solid-svg-icons';
import { ethers } from 'ethers';
import useBZZBalance from './useBZZBalance';

type Props = {
  price: string;
  waitingForBuy?: boolean;
  onSubmit: (values: BuyBatchInfo) => Promise<void>;
};
const BatchCreateForm = ({ price = '0', waitingForBuy, onSubmit }: Props) => {
  const [label, setLabel] = useState('');
  const [loading, setLoading] = useState(false);
  const [showDaiModal, setShowDaiModal] = useState(false);
  const { data: bzzBalance } = useBZZBalance();
  const bzzBalanceFormatted = Number(
    typeof bzzBalance === 'bigint' ? ethers.formatUnits(bzzBalance, 16) : '0',
  ).toFixed(3);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      const amount = ethers.parseUnits(price, 16);
      const depth = 20;
      onSubmit && (await onSubmit({ label, amount, depth }));
      setLabel('');
    } catch (e) {
      console.error(e);
      if (e instanceof Error) {
        alert(e.message);
      } else {
        alert(e as string);
      }
    } finally {
      setLoading(false);
    }
  };
  return (
    <Form onSubmit={handleSubmit}>
      <div className="py-2">
        <h1>
          <small>Balance: </small>
          <strong>{bzzBalanceFormatted} BZZ</strong>
        </h1>
        <Alert variant="warning">
          <h3>
            <FontAwesomeIcon icon={warningIcon} size="lg" className="me-2" />
            Before creating a new batch:
          </h3>
          <ul>
            <li className="d-flex justify-content-between mb-2">
              1. You need to buy Gnosis BZZ coins first:
              <Button size="sm" onClick={() => setShowDaiModal(true)}>
                Buy BZZ
              </Button>
            </li>
          </ul>
        </Alert>
      </div>
      <Form.Group className="mb-3">
        <Form.Label>Batch Label</Form.Label>
        <Form.Control
          type="text"
          name="label"
          placeholder="Enter a label for your batch"
          required
          value={label}
          onChange={(e) => setLabel(e.target.value)}
        />
      </Form.Group>
      <Form.Group className="mb-3">
        <Form.Label>Price</Form.Label>
        <InputGroup>
          <InputGroup.Text className="fw-bold">BZZ</InputGroup.Text>
          <Form.Control type="text" required readOnly value={price} />
        </InputGroup>
      </Form.Group>
      <Button type="submit" size="lg" className="my-3 w-100" disabled={loading || waitingForBuy}>
        {loading || waitingForBuy ? <FontAwesomeIcon icon={faSpinner} spin /> : 'Create New Batch'}
      </Button>

      <Modal size="xl" show={showDaiModal} centered onHide={() => setShowDaiModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Buy BZZ</Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <iframe
            title={'Buy BZZ'}
            src="https://jumper.exchange/?toChain=100&toToken=0xdBF3Ea6F5beE45c02255B2c26a16F300502F68da"
            className="w-100"
            style={{ height: '80vh' }}
          />
        </Modal.Body>
      </Modal>
    </Form>
  );
};

export default BatchCreateForm;
