import {
  Button,
  ButtonProps,
  ButtonToolbar,
  FormGroup,
  FormLabel,
  FormSelect,
  Modal,
  Stack,
} from 'react-bootstrap';
import { useEffect, useState } from 'react';
import useBuckets from '../components/buckets/useBuckets';
import BucketList from '../components/buckets/BucketList';
import { Bucket, BucketFormValues } from '../types/buckets';
import api from '../services/api';
import BucketCreateForm from '../components/buckets/BucketCreateForm';
import useBatches from '../components/batches/useBatches';
import { useNavigate } from 'react-router-dom';
import useNetworkCheck from '../components/common/useNetworkCheck';

const CreateBucketButton = ({ onClick, ...rest }: ButtonProps) => {
  return (
    <Button variant="primary" onClick={onClick} {...rest}>
      Create New Bucket
    </Button>
  );
};

const Buckets = () => {
  // const [view, setView] = useState<View>('grid');
  const [showCreateBucket, setShowCreateBucket] = useState(false);
  const [filterBatchId, setFilterBatchId] = useState('');
  const { data: batches, loaded: batchesLoaded } = useBatches();
  const { data: buckets, refresh: refreshBuckets } = useBuckets();
  const navigate = useNavigate();
  const isCorrectNetwork = useNetworkCheck();

  useEffect(() => {
    if (batchesLoaded && !batches?.length) {
      navigate('/batches');
    }
  }, [batches, batchesLoaded, navigate]);

  const handleCreateBucket = async (values: BucketFormValues) => {
    try {
      await api.post(`/buckets`, {
        batch_id: values.batchId,
        bucket: values.name,
      });
      await refreshBuckets();
      setShowCreateBucket(false);
    } catch (e) {
      console.error(e);
    }
  };

  const filterBuckets = (buckets: Bucket[], filterBatchId: string) => {
    if (!filterBatchId) return buckets;
    return buckets.filter((bucket) => bucket.Owner === filterBatchId);
  };

  return (
    <>
      <Stack>
        <Stack direction="horizontal">
          <h1 className="my-5 display-6 me-auto">My Buckets</h1>
          <CreateBucketButton
            disabled={!isCorrectNetwork}
            onClick={() => setShowCreateBucket(true)}
          />
        </Stack>
        <ButtonToolbar className="mb-5">
          {/*<GridListViewSwitcher*/}
          {/*  view={view}*/}
          {/*  onSwitch={(view) => setView(view)}*/}
          {/*  className="me-auto mb-2"*/}
          {/*/>*/}
          <FormGroup className="ms-auto" as={Stack} direction="horizontal">
            <FormLabel className="mb-0 me-3">Batch:</FormLabel>
            <FormSelect
              style={{ maxWidth: '20rem' }}
              onChange={(e) => setFilterBatchId(e.target.value)}
            >
              <option value="">All</option>
              {batches?.map((batch) => (
                <option key={batch.batch_id} value={batch.batch_id}>
                  {batch.formatted.fullLabel}
                </option>
              ))}
            </FormSelect>
          </FormGroup>
        </ButtonToolbar>
        {buckets?.length ? (
          <BucketList
            buckets={filterBuckets(buckets, filterBatchId)}
            view={'grid'}
            onChange={refreshBuckets}
          />
        ) : null}
      </Stack>
      <Modal show={showCreateBucket} onHide={() => setShowCreateBucket(false)} size="sm">
        <Modal.Header closeButton>
          <Modal.Title>Create Bucket</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <BucketCreateForm batches={batches} onSubmit={handleCreateBucket} />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default Buckets;
