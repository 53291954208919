import axios from 'axios';
import { getSignature, saveSignature } from './auth.service';

const api = axios.create({
  baseURL: '/api/json',
  timeout: 10000,
});

api.interceptors.request.use(
  (config) => {
    const signature = getSignature();
    config.headers = config.headers || {};
    config.headers.signature = signature;
    return config;
  },
  (error) => {
    return Promise.reject(error);
  },
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (error.response.status === 401) {
      saveSignature('');
      window.location.href = '/login';
    }
    return Promise.reject(error);
  },
);

export default api;
