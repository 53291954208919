import { Breadcrumb, Button, Card, Col, Modal, Row, Stack } from 'react-bootstrap';
import { CSSProperties, MouseEventHandler, useEffect, useState } from 'react';
import { View } from '../../types/buckets';
import useFiles from './useFiles';
import { File } from '../../types/files';
import FancyIcon from '../common/FancyIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faDownload as downloadIcon,
  faFolder as folderIcon,
  faHome as homeIcon,
  faUpload as uploadIcon,
} from '@fortawesome/free-solid-svg-icons';
import FileUploadForm from './FileUploadForm';
import FileMenuButton from './FileMenuButton';
import { isAxiosError } from 'axios';
import api from '../../services/api';
import { BatchWithFormattedValues } from '../../types/batches';

type FileCardProps = {
  file: File;
  view: View;
  onClick?: MouseEventHandler<HTMLDivElement>;
  onDelete?: () => void;
};
const FileCard = ({ file, view, onClick, onDelete }: FileCardProps) => {
  const dir = view === 'grid' ? 'vertical' : 'horizontal';
  // const fileUrl = `/api/json/buckets/${file.Bucket}/objects/${file.Key}`;
  const style: CSSProperties = {};
  if (file.Folder) {
    style['cursor'] = 'pointer';
  }

  const url = `/api/json/buckets/${file.Bucket}/objects/${file.Key}`;

  return (
    <Card onClick={onClick} style={style} className="w-100">
      <Card.Body className="w-100">
        <Stack direction="horizontal" gap={2} className="align-items-start w-100">
          <Stack direction={dir} gap={3} className="w-75">
            {file.Folder ? (
              <FancyIcon className="bg-dark text-primary">
                <FontAwesomeIcon icon={folderIcon} size="2x" />
              </FancyIcon>
            ) : (
              <FancyIcon className="bg-primary-300 text-gray-800" />
            )}
            <div className={`fw-bold ${view === 'list' ? 'w-50' : ''}`}>
              <Card.Title className="m-0 ellipsis">{file.Name}</Card.Title>
            </div>
            <Card.Text className="small">
              {new Date(file.CreationDate).toLocaleDateString()}
            </Card.Text>
          </Stack>
          {!file.Folder ? (
            <>
              <Button
                as={'a'}
                href={url}
                target="_blank"
                download={file.Name}
                variant="outline-secondary"
                title="Download"
                className="ms-auto rounded-circle border-0"
                size="sm"
              >
                <FontAwesomeIcon icon={downloadIcon} />
              </Button>
              <FileMenuButton onDelete={onDelete} />
            </>
          ) : null}
        </Stack>
      </Card.Body>
    </Card>
  );
};

type Props = {
  batch?: BatchWithFormattedValues | null;
  bucketId: string;
  className?: string;
};
const FilesList = ({ batch, bucketId, className }: Props) => {
  const [path, setPath] = useState('');
  // const [view, setView] = useState<View>('grid');
  const view = 'grid';
  const [uploadModalShow, setUploadModalShow] = useState(false);
  const [fileForDelete, setFileForDelete] = useState<File | null>(null);

  useEffect(() => {
    setPath('');
  }, [bucketId]);

  const { data: files, loadFiles } = useFiles(bucketId, path);

  const afterUploadHandler = async () => {
    await loadFiles();
    setUploadModalShow(false);
  };

  const openFolder = (file: File) => {
    setPath(file.Key);
  };

  const fileCardClickHandler = (file: File) => () => {
    return file.Folder ? openFolder(file) : null;
  };

  const deleteHandler = async () => {
    if (!fileForDelete) return;
    try {
      await api.delete(`/buckets/${bucketId}/objects/${fileForDelete.Key}`);
      setFileForDelete(null);
      await loadFiles();
    } catch (e) {
      console.error(e);
      if (isAxiosError(e)) {
        alert(e.response?.data?.message || e.message);
      } else {
        alert(e instanceof Error ? e.message : e);
      }
    }
  };

  return (
    <div className={className}>
      <Stack direction="horizontal" gap={2}>
        <h3 className="m-0 me-auto">ALL FILES</h3>
        <Button
          variant="secondary"
          onClick={() => setUploadModalShow(true)}
          disabled={!batch || !batch.info.exists}
        >
          <FontAwesomeIcon icon={uploadIcon} className="me-2" />
          Upload File
        </Button>
        {/*<GridListViewSwitcher view={view} onSwitch={setView} />*/}
      </Stack>

      <Breadcrumb className="mt-3 me-auto">
        <Breadcrumb.Item onClick={() => setPath('')}>
          <FontAwesomeIcon icon={homeIcon} className="me-2" />
          Root
        </Breadcrumb.Item>
        {path.split('/').map((folder, index) => (
          <Breadcrumb.Item
            key={index}
            active={index === path.split('/').length - 1}
            onClick={() =>
              setPath(
                path
                  .split('/')
                  .slice(0, index + 1)
                  .join('/'),
              )
            }
          >
            {folder}
          </Breadcrumb.Item>
        ))}
      </Breadcrumb>
      <Row className="mt-3 g-3">
        {files?.map((file) => {
          const md = view === 'grid' ? 6 : 12;
          const lg = view === 'grid' ? 4 : 12;
          return (
            <Col md={md} lg={lg} key={file.Key}>
              <FileCard
                file={file}
                view={view}
                onClick={fileCardClickHandler(file)}
                onDelete={() => setFileForDelete(file)}
              />
            </Col>
          );
        })}
      </Row>
      <Modal show={uploadModalShow} onHide={() => setUploadModalShow(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>
            Upload File in <strong>{path}</strong>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FileUploadForm bucketId={bucketId} prefix={path} onSubmit={afterUploadHandler} />
        </Modal.Body>
      </Modal>

      <Modal show={!!fileForDelete} onHide={() => setFileForDelete(null)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Warning</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you sure you want to delete this file <strong>{fileForDelete?.Name}</strong>
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setFileForDelete(null)}>
            Cancel
          </Button>
          <Button variant="danger" onClick={deleteHandler}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default FilesList;
