import { Batch, BatchWithFormattedValues } from '../../types/batches';
import { shortenAddress } from '../../utils/eth.utils';
import { ethers } from 'ethers';

export const formatTTL = (batch: Batch) => {
  const { batchTTL, expired } = batch.info;
  const ttl = Math.max(batchTTL / 60 / 60, 0);
  return expired ? 'Expired' : `${ttl.toFixed(2)} hours`;
};

export const formatAmount = (amount: number) => {
  return `${ethers.formatUnits(amount, 16)} BZZ`;
};

export const addFormattedValuesToBatch = (batch: Batch): BatchWithFormattedValues => {
  const id = shortenAddress(batch.batch_id);
  const label = batch.info.label || '<no label>';
  const ttl = formatTTL(batch);
  const amount = formatAmount(batch.info.amount);
  const fullLabel = `${label} - ${ttl}`;

  return {
    ...batch,
    formatted: {
      id,
      label,
      fullLabel,
      ttl,
      amount,
    },
  };
};
