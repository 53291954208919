import { Badge, Card, Col, Row } from 'react-bootstrap';
import CopyButton from '../common/CopyButton';
import { BatchWithFormattedValues } from '../../types/batches';

type Props = {
  batch?: BatchWithFormattedValues | null;
};
const BatchDetails = ({ batch }: Props) => {
  // const navigate = useNavigate();

  return (
    <Card className="mb-4">
      {batch ? (
        <Card.Body>
          <h2 className="mb-4 fw-bold">Batch Details</h2>
          <Row>
            <Col sm={6}>
              <ul className="list-unstyled">
                <li className="mb-3">
                  Batch Label - <strong>{batch.formatted.label}</strong>
                </li>
                <li className="mb-3" title={batch.batch_id}>
                  Batch ID - <strong>{batch.formatted.id}</strong>
                  <CopyButton text={batch.batch_id} />
                </li>
                <li className="mb-3">
                  TTL -{' '}
                  <Badge bg={!batch.info.exists ? 'danger' : 'success'}>
                    {batch.formatted.ttl}
                  </Badge>
                </li>
              </ul>
            </Col>
            <Col sm={6} className="text-end">
              <h1 className="display-6 fw-bold">{batch.formatted.amount}</h1>
              {/*<Button className="mt-auto" onClick={() => navigate('/batches')}>*/}
              {/*  Upgrade Batch*/}
              {/*</Button>*/}
            </Col>
          </Row>
        </Card.Body>
      ) : null}
    </Card>
  );
};

export default BatchDetails;
