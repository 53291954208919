import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClipboard as copyIcon,
  faClipboardCheck as copyCheckedIcon,
} from '@fortawesome/free-solid-svg-icons';

type Props = {
  text: string;
  showText?: boolean;
};
const CopyButton = ({ text, showText = false }: Props) => {
  const [copied, setCopied] = React.useState(false);

  const handleClick = async () => {
    await navigator.clipboard.writeText(text);

    setCopied(true);
    setTimeout(() => {
      setCopied(false);
    }, 3000);
  };

  return (
    <Button
      size="sm"
      variant="link"
      className="border-0 px-2 py-0"
      onClick={handleClick}
      title="Copy to clipboard"
    >
      <FontAwesomeIcon icon={copied ? copyCheckedIcon : copyIcon} />
      {copied ? showText && 'copied' : showText && 'copy'}
    </Button>
  );
};

export default CopyButton;
