import { BigNumberish, ethers } from 'ethers';

export const formatEther = (wei: BigNumberish | string | undefined, digits = 2) => {
  let formatted: string;
  if (!wei) {
    formatted = '0';
  } else if (typeof wei === 'string') {
    formatted = wei;
  } else {
    formatted = ethers.formatEther(wei);
  }
  return (+formatted).toFixed(digits);
};

export const shortenAddress = (address: string | undefined, size = 4) => {
  if (!address) return '';
  return `${address.slice(0, size)}...${address.slice(-size)}`;
};
