import { useParams } from 'react-router-dom';
import { Col, Row } from 'react-bootstrap';
import BucketMenu from '../components/buckets/BucketMenu';
import FilesList from '../components/files/FilesList';
import BatchDetails from '../components/batches/BatchDetails';
import useBuckets from '../components/buckets/useBuckets';
import useBatches from '../components/batches/useBatches';

const Bucket = () => {
  const { bucketId } = useParams();
  const { data: buckets, filterByBatchId, countByBatchId } = useBuckets();
  const { data: batches, getById } = useBatches();

  const bucket = buckets?.find((bucket) => bucket._id === bucketId);
  const batchId = bucket?.Owner;
  const batch = batchId ? getById(batchId) : null;

  return (
    <Row className="py-5">
      <Col lg={9} className="order-lg-1 mb-4">
        {bucketId ? (
          <>
            {batchId ? <BatchDetails batch={batch} /> : null}
            <FilesList batch={batch} bucketId={bucketId} className="my-4" />
          </>
        ) : null}
      </Col>

      <Col as="aside" lg={3}>
        <BucketMenu
          bucket={bucket}
          batches={batches}
          filterByBatchId={filterByBatchId}
          countByBatchId={countByBatchId}
        />
      </Col>
    </Row>
  );
};

export default Bucket;
