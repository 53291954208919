import { useAccount, useContractRead } from 'wagmi';
import { BZZ_COIN_ADDRESS } from '../../settings';

const balanceOfAbi = [
  {
    inputs: [
      {
        internalType: 'address',
        name: 'account',
        type: 'address',
      },
    ],
    name: 'balanceOf',
    outputs: [
      {
        internalType: 'uint256',
        name: '',
        type: 'uint256',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
];

const useBZZBalance = () => {
  const { address } = useAccount();
  const { data, ...rest } = useContractRead({
    address: BZZ_COIN_ADDRESS,
    abi: balanceOfAbi,
    functionName: 'balanceOf',
    args: [address],
  });
  return { data, ...rest } as const;
};

export default useBZZBalance;
