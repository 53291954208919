import React, { useEffect, useState } from 'react';
import './App.css';
import { useAccount, useNetwork, useSwitchNetwork } from 'wagmi';
import { DEFAULT_CHAIN } from './settings';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Layout from './components/Layout';
import Buckets from './pages/Buckets';
import Bucket from './pages/Bucket';
import Batches from './pages/Batches';

function App() {
  const [firstTime, setFirstTime] = useState(true);
  const { chain } = useNetwork();
  const { isConnected } = useAccount();
  const navigate = useNavigate();
  const { switchNetwork } = useSwitchNetwork({
    chainId: DEFAULT_CHAIN.id,
  });

  useEffect(() => {
    if (!isConnected) {
      navigate('/login');
    }
  }, [isConnected, navigate]);

  useEffect(() => {
    if (chain?.id !== DEFAULT_CHAIN.id && switchNetwork && firstTime) {
      setFirstTime(false);
      switchNetwork(DEFAULT_CHAIN.id);
    }
  }, [chain, switchNetwork, firstTime]);

  return (
    <Layout>
      <Routes>
        <Route index element={<Buckets />} />
        <Route path="/batches" element={<Batches />} />
        <Route path="/:bucketId" element={<Bucket />} />
      </Routes>
    </Layout>
  );
}

export default App;
