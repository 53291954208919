import { DetailedHTMLProps, HTMLAttributes } from 'react';
import { ReactComponent as DocumentIcon } from '../../assets/document.svg';

type Props = {
  size?: string;
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>;
const FancyIcon = ({
  className = 'bg-secondary text-dark',
  size = '3rem',
  children,
  style,
}: Props) => {
  return (
    <div
      className={`rounded p-1 ${className}`}
      style={{
        width: size,
        height: size,
        display: 'grid',
        placeItems: 'center',
        ...style,
      }}
    >
      {children ? children : <DocumentIcon />}
    </div>
  );
};

export default FancyIcon;
