import { prepareWriteContract, readContract, waitForTransaction, writeContract } from '@wagmi/core';
import { BEE_ETHEREUM_ADDRESS, BZZ_COIN_ADDRESS } from '../settings';
import approveAbi from '../utils/abi/approveAbi.json';
import {
  BatchAsyncCreationResponse,
  BatchAsyncExtendResponse,
  BuyBatchInfo,
} from '../types/batches';
import api from './api';

export const approve = async (amount: bigint, address: `0x${string}` | undefined) => {
  const allowed = await readContract({
    address: BZZ_COIN_ADDRESS,
    abi: approveAbi,
    functionName: 'allowance',
    args: [address, BEE_ETHEREUM_ADDRESS],
  });
  if (typeof allowed === 'bigint' && allowed >= amount) return;
  const config = await prepareWriteContract({
    address: BZZ_COIN_ADDRESS,
    abi: approveAbi,
    functionName: 'approve',
    args: [BEE_ETHEREUM_ADDRESS, amount],
  });
  const { hash } = await writeContract(config);
  await waitForTransaction({ hash });
};

export const createBatch = async (params: BuyBatchInfo) => {
  const { data } = await api.post<BatchAsyncCreationResponse>('/batches', {
    amount: Number(params.amount),
    depth: params.depth,
    label: params.label,
  });
  return data;
};

export const extendBatch = async (batchId: string, amount: number) => {
  const { data } = await api.post<BatchAsyncExtendResponse>(`/batches/${batchId}/_extend`, {
    amount,
  });
  return data;
};

// TODO: move other api calls here
