import BuyBatch from '../components/batches/BuyBatch';
import useBatches from '../components/batches/useBatches';
import BatchList from '../components/batches/BatchList';

const Batches = () => {
  const { data: batches, refresh: refreshBatches } = useBatches();

  const afterBuyHandler = async () => {
    await refreshBatches();
    alert('Batch has been created successfully.');
  };

  return (
    <div className="py-4">
      <BuyBatch onAfterBuy={afterBuyHandler} />
      <br />
      <br />
      <BatchList batches={batches || []} onChange={() => refreshBatches()} />
    </div>
  );
};

export default Batches;
