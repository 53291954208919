import React from 'react';
import ReactDOM from 'react-dom/client';
import './scss/custom.scss';
import './index.css';
import App from './App';
import { configureChains } from '@wagmi/core';
import { DEFAULT_CHAIN, WALLET_CONNECT_PROJECT_ID } from './settings';
import { EthereumClient, w3mConnectors, w3mProvider } from '@web3modal/ethereum';
import { createConfig, WagmiConfig } from 'wagmi';
import { Web3Modal } from '@web3modal/react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Login from './pages/Login';
import Theme from './pages/Theme';

const chains = [DEFAULT_CHAIN];
const projectId = WALLET_CONNECT_PROJECT_ID;
const { publicClient } = configureChains(chains, [w3mProvider({ projectId })]);
const web3Config = createConfig({
  autoConnect: true,
  connectors: w3mConnectors({ projectId, chains }),
  publicClient,
});

const ethereumClient = new EthereumClient(web3Config, chains);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <React.StrictMode>
    <WagmiConfig config={web3Config}>
      <BrowserRouter>
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/theme" element={<Theme />} />
          <Route path="*" element={<App />} />
        </Routes>
      </BrowserRouter>
    </WagmiConfig>
    <Web3Modal
      defaultChain={DEFAULT_CHAIN}
      projectId={projectId}
      ethereumClient={ethereumClient}
      themeVariables={{
        '--w3m-font-family': 'Roboto, sans-serif',
        '--w3m-accent-color': '#ead30c',
      }}
    />
  </React.StrictMode>,
);
