import Header from './Header';
import { PropsWithChildren } from 'react';
import { Container } from 'react-bootstrap';

const Layout = ({ children }: PropsWithChildren) => {
  return (
    <>
      <Header />
      <Container>
        <main>{children}</main>
      </Container>
    </>
  );
};

export default Layout;
