import React, { useState } from 'react';
import { Button, Card, Form, Stack } from 'react-bootstrap';
import FancyIcon from '../common/FancyIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faClock as expireIcon,
  faRightLeft as exchangeIcon,
  faSpinner,
} from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as BzzIcon } from '../../assets/swarm.svg';
import api from '../../services/api';
import { ethers } from 'ethers';

const MINIMUM_AMOUNT = 0.5;
let timeoutId: NodeJS.Timeout;

type Props = {
  disabled?: boolean;
  isProcessing?: boolean;
  buttonText?: string;
  onButtonClick?: (amount: string) => Promise<void>;
};
const TimeCalculator = ({ disabled, isProcessing, buttonText, onButtonClick }: Props) => {
  const [time, setTime] = useState(''); // hours
  const [amount, setAmount] = useState('');

  const debounce = (func: Function, wait = 300) => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    timeoutId = setTimeout(() => {
      func();
    }, wait);
  };

  const changeTimeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setTime(value);
    // setDir('down');
    debounce(() => {
      api
        .get('/batches/price', {
          params: { time_in_minutes: Math.round(Number(value || 0) * 60) },
        })
        .then(({ data }) => {
          setAmount('' + ethers.formatUnits(data['bzz_amount'], 16));
        })
        .catch((e) => {
          console.error(e);
          if (e instanceof Error) {
            alert(e.message);
          } else {
            alert(e as string);
          }
        });
    });
  };
  const changeAmountHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setAmount(value);
    // setDir('up');
    debounce(() => {
      api
        .get('/batches/ttl', {
          params: { bzz_amount: ethers.parseUnits(value || '0', 16) },
        })
        .then(({ data }) => {
          setTime('' + (data['time_in_minutes'] / 60).toFixed(2));
        })
        .catch((e) => {
          console.error(e);
          if (e instanceof Error) {
            alert(e.message);
          } else {
            alert(e as string);
          }
        });
    });
  };

  const buyClickHandler = async () => {
    if (Number(amount) < MINIMUM_AMOUNT) {
      return alert(`Minimum amount is ${MINIMUM_AMOUNT} BZZ`);
    }
    onButtonClick && (await onButtonClick(amount));
  };

  return (
    <div>
      <Card className="rounded-5 position-relative">
        <Card.Body className="p-5">
          <h4 className="fw-bold mb-3">YOU PAY</h4>
          <Stack direction="horizontal" gap={3} className="align-items-center mb-3">
            <FancyIcon size="4rem" className="bg-primary rounded-4">
              <BzzIcon />
            </FancyIcon>
            <h1 className="m-0">$BZZ</h1>
            <Form.Group className="ms-auto" style={{ width: '14rem' }}>
              <Form.Control
                type="number"
                min={MINIMUM_AMOUNT}
                className="text-end rounded-4 border-0 bg-body-secondary p-3"
                size="lg"
                placeholder="0.00"
                value={amount}
                onChange={changeAmountHandler}
              />
            </Form.Group>
          </Stack>
          <p>Swarm</p>

          <div
            className="bg-body-secondary"
            style={{
              position: 'absolute',
              display: 'grid',
              placeItems: 'center',
              border: '4px solid #111',
              borderRadius: '40%',
              width: '3.75rem',
              height: '3.75rem',
              bottom: -66,
              left: '50%',
              zIndex: 1,
              transform: 'translate(-50%, -50%)',
            }}
          >
            <FontAwesomeIcon icon={exchangeIcon} rotation={90} />
          </div>
        </Card.Body>
      </Card>
      <Card className="rounded-5 mt-1">
        <Card.Body className="p-5">
          <h4 className="fw-bold mb-3">YOU BUY</h4>
          <Stack direction="horizontal" gap={3} className="align-items-center mb-3">
            <FancyIcon size="4rem" className="bg-primary rounded-4">
              <FontAwesomeIcon icon={expireIcon} size="2x" />
            </FancyIcon>
            <h1 className="m-0">Hours</h1>
            <Form.Group className="ms-auto" style={{ width: '14rem' }}>
              <Form.Control
                type="number"
                className="text-end rounded-4 border-0 bg-body-secondary p-3"
                size="lg"
                placeholder="0.00"
                value={time}
                onChange={changeTimeHandler}
              />
            </Form.Group>
          </Stack>
          <p>Batch duration</p>
          <hr />
          <small className="text-muted">
            The batch duration time shown above might differ from the actual batch duration.
          </small>
        </Card.Body>
      </Card>

      <div className="mt-5 d-flex justify-content-center">
        <Button
          variant="primary"
          className="w-25"
          size="lg"
          onClick={buyClickHandler}
          disabled={disabled || !Number(amount)}
        >
          {isProcessing ? (
            <>
              <FontAwesomeIcon icon={faSpinner} spin={true} size="sm" className="me-2" />
              Buying...
            </>
          ) : (
            buttonText || 'Buy Batch'
          )}
        </Button>
      </div>
    </div>
  );
};

export default TimeCalculator;
