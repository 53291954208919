import { Button, Card, Container, Stack } from 'react-bootstrap';
import React from 'react';

const Theme = () => {
  return (
    <Container className="p-5">
      <h1>React Bootstrap</h1>
      <Card className="bg-primary-950">
        <Card.Body>
          <Card.Title>Primary Card Title</Card.Title>
          <Card.Text>
            Some quick example text to build on the card title and make up the bulk of the card's
            content.
          </Card.Text>
        </Card.Body>
      </Card>
      <Stack style={{ width: '25rem' }} gap={2}>
        <Card>
          <Card.Body>
            <Stack gap={2}>
              <p>
                Edit <code>src/App.tsx</code> and save to reload.
              </p>
              <Button variant="primary">Primary</Button>
              <Button variant="primary" className="bg-primary-300">
                Primary
              </Button>
              <Button variant="primary" disabled>
                Primary Disabled
              </Button>
              <Button variant="secondary">Secondary</Button>
              <Button variant="success">Success</Button>
              <Button variant="warning">Warning</Button>
              <Button variant="danger">Danger</Button>
              <Button variant="info">Info</Button>
              <Button variant="outline-primary">Primary</Button>
              <Button variant="outline-secondary">Secondary</Button>
              <Button variant="outline-success">Success</Button>
              <Button variant="outline-warning">Warning</Button>
              <Button variant="outline-danger">Danger</Button>
              <Button variant="outline-info">Info</Button>
            </Stack>
          </Card.Body>
        </Card>
        <Card>
          <Card.Body>
            <Card.Title>Card Title</Card.Title>
            <Card.Text>
              Some quick example text to build on the card title and make up the bulk of the card's
              content.
            </Card.Text>
          </Card.Body>
        </Card>
      </Stack>
    </Container>
  );
};

export default Theme;
