import { gnosis } from 'viem/chains';

export const WALLET_CONNECT_PROJECT_ID = '4e8a3f79afbffa3cc20950b38dcff42a';
export const DEFAULT_CHAIN = gnosis;
export const BZZ_COIN_ADDRESS =
  (process.env.REACT_APP_BZZ_COIN_ADDRESS as `0x${string}`) ||
  '0xdbf3ea6f5bee45c02255b2c26a16f300502f68da';
export const BEE_ETHEREUM_ADDRESS =
  (process.env.REACT_APP_BEE_ETHEREUM_ADDRESS as `0x${string}`) ||
  '0xb93c01a0516d2b3e73dc27ee97869e5b379944ba';
export const BATCH_PRICE = '0.0000001';
