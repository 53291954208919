import React, { useState } from 'react';
import { BatchWithFormattedValues } from '../../types/batches';
import { Badge, Button, Card, Col, Modal, Row } from 'react-bootstrap';
import FancyIcon from '../common/FancyIcon';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDatabase as batchIcon } from '@fortawesome/free-solid-svg-icons';
import { faClock as expireIcon } from '@fortawesome/free-regular-svg-icons';
import useBuckets from '../buckets/useBuckets';
import BatchExtendForm from './BatchExtendForm';
import CopyButton from '../common/CopyButton';

type BatchCardProps = {
  batch: BatchWithFormattedValues;
  countByBatchId: (batchId: string) => number;
  onExtendButtonClick: () => void;
};
const BatchCard = ({ batch, countByBatchId, onExtendButtonClick }: BatchCardProps) => {
  return (
    <Card>
      <Card.Body className="d-flex gap-3 align-items-center">
        <FancyIcon className="bg-primary-300 text-gray-800">
          <FontAwesomeIcon icon={batchIcon} size="2x" />
        </FancyIcon>
        <div>
          <h2 className="m-0">
            {batch.formatted.label}
            <span className="ms-2" title={batch.batch_id}>
              ({batch.formatted.id})
            </span>
            <CopyButton text={batch.batch_id} />
          </h2>
          {batch.info.exists ? (
            <Button variant="primary" size="sm" onClick={onExtendButtonClick}>
              Extend
            </Button>
          ) : null}
        </div>
        <div className="ms-auto text-end">
          <p className={`m-0 text-nowrap ${!batch.info.exists ? 'text-danger' : 'text-success'}`}>
            <FontAwesomeIcon icon={expireIcon} className="me-2" />
            {batch.formatted.ttl}
          </p>
          <p className="fw-bold m-0 text-nowrap">{batch.formatted.amount}</p>
          <Badge pill className="m-0">
            {countByBatchId(batch.batch_id)} Buckets
          </Badge>
        </div>
      </Card.Body>
    </Card>
  );
};

type Props = {
  batches: BatchWithFormattedValues[];
  onChange?: () => Promise<void> | void;
};
const BatchList = ({ batches, onChange }: Props) => {
  const [batchForExtend, setBatchForExtend] = useState<BatchWithFormattedValues | null>(null);
  const { countByBatchId } = useBuckets();

  const afterExtendHandler = async () => {
    setBatchForExtend(null);
    onChange && (await onChange());
  };

  return (
    <div>
      <h1>My Batch List</h1>
      {batches.length === 0 ? (
        <p className="p-5 text-center text-muted">You have no batches.</p>
      ) : null}
      <Row>
        {batches.map((batch) => (
          <Col key={batch.batch_id} xs={12} className="mb-3">
            <BatchCard
              batch={batch}
              countByBatchId={countByBatchId}
              onExtendButtonClick={() => setBatchForExtend(batch)}
            />
          </Col>
        ))}
      </Row>
      <Modal size="lg" show={!!batchForExtend} onHide={() => setBatchForExtend(null)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Extend Batch</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {batchForExtend && (
            <BatchExtendForm batch={batchForExtend} onExtend={afterExtendHandler} />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default BatchList;
