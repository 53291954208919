import { useEffect, useState } from 'react';
import api from '../../services/api';
import { AxiosError, isAxiosError } from 'axios';

type Options = {
  taskId: string | null;
  interval?: number;
  onSuccess?: () => Promise<void> | void;
  onFail?: (e: AxiosError) => Promise<void> | void;
};
const useWaitingForBatchTask = ({ taskId, interval = 2000, onSuccess, onFail }: Options) => {
  const [waiting, setWaiting] = useState(false);

  useEffect(() => {
    if (!taskId) return;
    setWaiting(true);
    const intervalId = setInterval(async () => {
      console.debug(`Checking task status... (${taskId})`);
      try {
        await api.get(`batches/tasks/${taskId}`);
        setWaiting(false);
        await onSuccess?.();
      } catch (e) {
        console.error(e);
        if (!isAxiosError(e)) {
          setWaiting(false);
          throw e;
        }
        const { data, status, statusText } = e.response || {};
        if (status !== 425) {
          setWaiting(false);
          await onFail?.(e);
          if (status === 402) {
            alert('Not enough funds to buy batch. Please buy more BZZ.');
          } else {
            alert(`Error ${status}: ${data?.message || statusText || 'Unknown error'}`);
          }
        }
      }
    }, interval);
    return () => clearInterval(intervalId);
  }, [taskId, interval, onSuccess, onFail]);

  return { waiting };
};

export default useWaitingForBatchTask;
