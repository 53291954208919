import React, { ChangeEvent, useState } from 'react';
import { Button, Form, FormControl, FormGroup, Spinner } from 'react-bootstrap';
import { BucketFormValues } from '../../types/buckets';
import { Link } from 'react-router-dom';
import { BatchWithFormattedValues } from '../../types/batches';

type Props = {
  batches: BatchWithFormattedValues[];
  onSubmit: (values: BucketFormValues) => Promise<void>;
};

const BucketCreateForm = ({ batches, onSubmit }: Props) => {
  const defaultValues: BucketFormValues = { name: '', batchId: '' };
  const [formValues, setFormValues] = useState<BucketFormValues>(defaultValues);
  const [loading, setLoading] = useState(false);

  const handleChange = (
    e: ChangeEvent<HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setFormValues((prev) => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);
    try {
      onSubmit && (await onSubmit(formValues));
      setFormValues(defaultValues);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Form onSubmit={handleSubmit}>
      <FormGroup className="mb-3">
        <Form.Label>Batch (Storage)</Form.Label>
        <Form.Select name="batchId" required value={formValues.batchId} onChange={handleChange}>
          <option value="">Select a batch</option>
          {batches
            ?.filter((batch) => !batch.info.exists)
            ?.map((batch) => (
              <option key={batch.batch_id} value={batch.batch_id}>
                {batch.formatted.fullLabel}
              </option>
            ))}
        </Form.Select>

        <Link to="/batches" className="d-block text-end small mt-2">
          Buy New Storage
        </Link>
      </FormGroup>

      <FormGroup className="mb-3">
        <Form.Label>Bucket name</Form.Label>
        <FormControl
          type="text"
          name="name"
          required
          value={formValues.name}
          onChange={handleChange}
          placeholder="Bucket name"
        />
      </FormGroup>
      <Button type="submit" className="w-100" disabled={loading}>
        {loading ? <Spinner role="status" size="sm" /> : 'Create'}
      </Button>
    </Form>
  );
};

export default BucketCreateForm;
