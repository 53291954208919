import { Badge, Card, Collapse, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown as expandIcon,
  faChevronUp as collapseIcon,
  faDatabase as batchIcon,
  faHardDrive as bucketIcon,
} from '@fortawesome/free-solid-svg-icons';
import { Bucket } from '../../types/buckets';
import { BatchWithFormattedValues } from '../../types/batches';
import { useEffect, useState } from 'react';

type Props = {
  batches: BatchWithFormattedValues[];
  bucket?: Bucket;
  filterByBatchId: (batchId: string) => Bucket[];
  countByBatchId: (batchId: string) => number;
};
const BucketMenu = ({ batches, bucket, filterByBatchId, countByBatchId }: Props) => {
  const bucketId = bucket?._id;
  const [expandedBatch, setExpandedBatch] = useState<string | null>(bucket?.Owner || null);

  const toggleExpandedBatch = (batchId: string) => {
    setExpandedBatch((prev) => (prev === batchId ? null : batchId));
  };

  useEffect(() => {
    if (bucket?.Owner) {
      setExpandedBatch(bucket.Owner);
    }
  }, [bucket]);

  return (
    <Card className="rounded-4">
      <Card.Body className="p-3">
        <h3 className="fw-bold mb-4">My Buckets</h3>

        {batches
          .filter((batch) => countByBatchId(batch.batch_id) > 0)
          .map((batch) => (
            <>
              <div
                key={batch.batch_id}
                className="d-flex align-items-center p-1"
                style={{ cursor: 'pointer' }}
                onClick={() => toggleExpandedBatch(batch.batch_id)}
              >
                <FontAwesomeIcon icon={batchIcon} className="ms-2 me-3" size="lg" />
                <span className="ellipsis">{batch.formatted.fullLabel}</span>
                <div className="ms-auto me-2">
                  <Badge pill>{countByBatchId(batch.batch_id)}</Badge>
                </div>
                <FontAwesomeIcon
                  icon={expandedBatch === batch.batch_id ? collapseIcon : expandIcon}
                  size="sm"
                />
              </div>

              <Collapse in={expandedBatch === batch.batch_id}>
                <Nav
                  defaultActiveKey={bucketId}
                  className="flex-column flex-nowrap"
                  variant="pills"
                >
                  {filterByBatchId(batch.batch_id)?.map((bucket) => (
                    <Nav.Link
                      key={bucket._id}
                      as={Link}
                      eventKey={bucket._id}
                      to={`/${bucket._id}`}
                      className="d-block fw-bold py-2"
                    >
                      <FontAwesomeIcon icon={bucketIcon} className="ms-2 me-3" size="lg" />
                      {bucket.Name}
                    </Nav.Link>
                  ))}
                </Nav>
              </Collapse>
            </>
          ))}
      </Card.Body>
    </Card>
  );
};

export default BucketMenu;
